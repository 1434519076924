window.onload = function () {

  /* accessibility
  -------------------------------------------------- */
  (function (window, document, undefined) {
    'use strict';
    if (!('localStorage' in window)) return;
    var contrast = localStorage.getItem('high-contrast');
    if (contrast) {
      document.documentElement.className += ' high-contrast';
    }
    var fontsize = localStorage.getItem('font-size');
    if (fontsize) {
      document.documentElement.className += ' font-size';
    }
  })(window, document);

  var contrast = document.querySelector('.js-togglecontrast');
  if (!contrast) return;

  contrast.addEventListener('click', function (event) {
    event.preventDefault();
    document.documentElement.classList.toggle('high-contrast');
    contrast.classList.toggle('active');
    if (document.documentElement.classList.contains('high-contrast')) {
      localStorage.setItem('high-contrast', true);
      return;
    }
    localStorage.removeItem('high-contrast');
  },
    false);
  var fontsize = document.querySelector('.js-togglefontsize');
  if (!fontsize) return;

  fontsize.addEventListener('click', function (event) {
    event.preventDefault();
    document.documentElement.classList.toggle('font-size');
    if (document.documentElement.classList.contains('font-size')) {
      localStorage.setItem('font-size', true);
      return;
    }
    localStorage.removeItem('font-size');
  }, false);





  /* show / hide employee vita
  -------------------------------------------------- */
  const toggler = document.querySelectorAll('div.js-toggle-employee-list');

  toggler.forEach((link) => {
    link.addEventListener('click', () => {
      link.classList.toggle('show');
    });
  });

};
