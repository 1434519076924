// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import Swiper JS
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

const swiper_home = new Swiper('.swiper-home', {
  loop: true,

  modules: [Navigation, Pagination, Autoplay],
  autoplay: {
    delay: 3800,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination",
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});


const swiper_normal = new Swiper(".gallery", {
  modules: [Navigation, Pagination],
  loop: true,
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

export default Swiper;